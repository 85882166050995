import { convertUtcToMMDDYYHHMM, convertUtcToMMDD, convertUtcToMMDDYY } from "./utils";

const DEFAULT_VALUE = "N/A";

class sslModel {
  constructor(data) {
    this.Id = data?.Id || DEFAULT_VALUE;
    this.scac = data?.Scac || DEFAULT_VALUE;
    this.ssl = data?.SSL || DEFAULT_VALUE;
    this.s_t = data?.SizeType || DEFAULT_VALUE;
    this.cont_trl_num = data?.Container || (data?.Tmp) || DEFAULT_VALUE;
    this.chassis_n = data.Chassis === null && data.Status === 'InYard' ? "GROUNDED" : data?.Chassis || DEFAULT_VALUE;
    this.lfd = data?.LastFreeDay
      ? convertUtcToMMDD(data.LastFreeDay)
      : DEFAULT_VALUE;
    this.status = data?.Status
      ? (() => {
        switch (data.Status) {
          case "InYard":
            return "In Yard";
          case "PendingInspect":
            return "In Yard"
          case "OutYard":
            return "Out Yard";
          case "InTransit":
            return "In Transit";
          case "PendingFlip":
            return "Pending Flip";
          default:
            return DEFAULT_VALUE;
        }
      })()
      : DEFAULT_VALUE;
    this.arrival = data?.Arrival
      ? convertUtcToMMDDYYHHMM(data.Arrival)
      : DEFAULT_VALUE;
    this.location = data?.Location || DEFAULT_VALUE;
    this.spot = data?.spot || DEFAULT_VALUE;
    this.statusaprove = data?.StatusSt || DEFAULT_VALUE;
    this.statusInspection = data?.statusInspection === "True" ? "Completed" : "Pending";
    this.newScacCode = data?.NewScacCode;
    this.needChassis = data?.NeedChassis;
    this.streetTurnDate = data?.DateStart
      ? convertUtcToMMDD(data.DateStart)
      : DEFAULT_VALUE;
    this.requestDate = data?.DateRequest
      ? convertUtcToMMDD(data.DateRequest)
      : DEFAULT_VALUE;
    this.statusInspection = data?.StatusInspection === "True" ? "Completed" : "Pending";
    this.deliveryDate = data?.Deliverydate ? convertUtcToMMDDYY(data.Deliverydate) : DEFAULT_VALUE;
    this.carrier = data?.Carrier || DEFAULT_VALUE;
    this.deliveryLocation = data?.DeliveryLocation || DEFAULT_VALUE;
    this.foodGrade = data?.FoodGrade ? "Yes" : "No";
    this.tmp = (data?.Tmp || DEFAULT_VALUE).replace(/UNK1-/g, '');

  }
}

export default sslModel;
