import React, { useState, useEffect, useRef, useContext } from "react";
import { UpdateStatusStreetTurn, GetFileDownload } from "../../services/services";
import { StoreContext } from "../../store/storeContext";
import useStatusTableController from "../statusTable/statusTableController";

const useModalEditController = () => {
  let { shownModal, handleFillShownModal, modalData, user } =
    useContext(StoreContext);
  let [newModalData, setNewModalData] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  let [streetTurnInput, setStreetTurnInput] = useState(false);
  const [keepChassis, setKeepChassis] = useState(null);
  const [dateStSelected, setDateStSelected] = useState(null);
  const [newScacSelected, setNewScacSelected] = useState(null);
  const [lineApprovedComing, setLineApprovedComing] = useState(false)
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("Attach Docs");
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [saveAvailable, setSaveAvailable] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("");

  const [savingStatus, setSavingStatus] = useState(false)

  // CONST OF FILES

  const [apiFilesNames, setApiFilesNames] = useState([]);
  const [localFilesNames, setLocalFilesNames] = useState([]);
  const [apiFiles, setApiFiles] = useState([]);
  const [localFiles, setLocalFiles] = useState([]);
  const [listShowedOfFiles, setListShowedOfFiles] = useState([]);
  const [IdOfFiles, setIdOfFiles] = useState('')



  const readElementsById = (id) => {
    const Id = document.getElementById("Id");
    const scacText = document.getElementById("scacText");
    const sslText = document.getElementById("sslText");
    const stText = document.getElementById("stText");
    const contNumText = document.getElementById("contNumText");
    const chassisText = document.getElementById("chassisText");
    const statusText = document.getElementById("statusText");
    const locationText = document.getElementById("locationText");
    const spotText = document.getElementById("spotText");
    const formSelect = document.getElementById("formSelect-st-pending");
    const lfdDateInput = document.getElementById("lfdDate");
    const arrivalDateInput = document.getElementById("arrivalDate");
    const streetTurnDateInput = document.getElementById("streetTurnDate-st-pending");
    const changeOfScac = document.getElementById("changeOfScac");

    return {
      Id,
      scacText,
      sslText,
      stText,
      contNumText,
      chassisText,
      statusText,
      locationText,
      spotText,
      formSelect,
      lfdDateInput,
      arrivalDateInput,
      streetTurnDateInput,
      changeOfScac,
    };
  };
  const formatDateToInputValue = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = (date.getDate()).toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };







  useEffect(() => {
    if (modalData) {

      const inputsModal = readElementsById();
      inputsModal.Id.value = modalData.Id;
      inputsModal.sslText.value = modalData.SSL;
      inputsModal.scacText.value = modalData.Scac;
      inputsModal.stText.value = modalData.SizeType;
      inputsModal.contNumText.value = modalData.Container;
      inputsModal.chassisText.value = modalData.Chassis;
      inputsModal.statusText.value = modalData.StatusSt;
      inputsModal.locationText.value = modalData.Location;
      inputsModal.spotText.value = modalData.spot;

      if (modalData.StatusSt === "PendingFlip" || modalData.StatusSt === "Finished") {
        setSaveAvailable(false)
        setLineApprovedComing(true)
        setStreetTurnInput(true);

        setTimeout(() => {
          const streetTurnDateInput = document.getElementById("streetTurnDate-st-pending");
          const changeOfScac = document.getElementById("changeOfScac");
          const KeppChassisNoRadio = document.getElementById("keepChassisNo");
          const KeepChassisYesRadio = document.getElementById("keepChassisYes");

          if (modalData.DateStart) {
            streetTurnDateInput.value = modalData.DateStart ? formatDateToInputValue(modalData.DateStart) : "";
          }
          if (modalData.Files && modalData.Files.length > 0) {
            const archivos = modalData.Files.map(file => {
              const nombreArchivo = obtenerNombreArchivo(file);
              return nombreArchivo;
            });
            const archivosID = modalData.Files.map(
              file => {
                const getID = obtenerIdOfFile(file)

                return getID;
              }
            )
            // Actualizar el estado directamente con los nombres de los archivos
            setApiFilesNames(archivos);
            setIdOfFiles(archivosID)
          }
          KeppChassisNoRadio.disabled = true
          KeepChassisYesRadio.disabled = true
          changeOfScac.value = modalData.NewScacCode;
          setKeepChassis(null)
          setKeepChassis(modalData.NeedChasis)
          streetTurnDateInput.disabled = true;
          changeOfScac.disabled = true;
          inputsModal.formSelect.value = "LineAproved"
          inputsModal.formSelect.disabled = true;
        }, 200);

      }
      else {

        setSaveAvailable(true)
        inputsModal.formSelect.disabled = false
      }
      inputsModal.formSelect.value = modalData.StatusSt;
      inputsModal.lfdDateInput.value = modalData.LastFreeDay
        ? formatDateToInputValue(modalData.LastFreeDay)
        : "";
      inputsModal.arrivalDateInput.value = modalData.Arrival
        ? formatDateToInputValue(modalData.Arrival)
        : "";

      const TrashIcon = document.getElementById("trashIcon-st-pending")
      if (TrashIcon) {
        TrashIcon.style.display = "none";
      }

    }
  }, [modalData]);

  const handleCloseModal = () => {
    handleFillShownModal(false, null);
    setStreetTurnInput(false);
    setAttachments([]);
    setApiFiles([])
    setApiFilesNames([])
    setLocalFiles([])
    setLocalFilesNames([])
    setIdOfFiles('')
    setLineApprovedComing(false)
    setSelectedStatus("");

  };

  //---------------------PRUEBAS ------------------------------
  React.useEffect(() => {
    const forEachLocalFiles = localFiles.map((file) => {

      return file.fileName;
    })

    const updateFiles = ([...forEachLocalFiles, ...apiFilesNames]);
    setListShowedOfFiles(updateFiles);

  }, [apiFilesNames, localFiles]);





  //-----------------------------------------------------------------------
  const convertToUTC = (dateString) => {

    const date = new Date(`${dateString}T00:00:00Z`);

    return date;
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
      };
      reader.readAsDataURL(file);
    });
  };


  const obtenerNombreArchivo = (url) => {
    const partesUrl = url.split('/');
    const nombreArchivoConEspacios = partesUrl[partesUrl.length - 1];
    // Reemplazar "%20" con espacios en blanco
    return nombreArchivoConEspacios.replace(/%20/g, ' ');
  }
  const obtenerIdOfFile = (url) => {
    const partesUrl = url.split('/');
    const ID = partesUrl[partesUrl.length - 2];
    return ID
  }





  const processFiles = async (files) => {
    const processedFiles = [];

    for (let i = 0; i < files.length; i++) {
      const currentFile = files[i];
      const base64Data = await fileToBase64(currentFile);

      processedFiles.push({
        fileName: currentFile.name,
        file: base64Data,
        type: currentFile.type,
      });
    }

    return processedFiles;
  };

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;

    // Filtra los archivos para incluir PDF, PNG y JPG
    const validFiles = Array.from(selectedFiles).filter((file) =>
      ['application/pdf', 'image/png', 'image/jpeg'].includes(file.type)
    );

    if (validFiles.length === 0) {
      // Muestra un mensaje o realiza alguna acción si no se seleccionan archivos válidos
      handleSetToastMessage('Select a PDF, PNG, or JPG file');
      handleSetToastType("error");
      handleSetShowToast(true);
      return;
    }

    const result = await processFiles(validFiles);
    fileInputRef.current.value = '';

    const uniqueFiles = result.filter((newFile) => {
      return !files.some((existingFile) => existingFile.fileName === newFile.fileName);
    });
    const validApiFiles = result.filter((newFile) => {
      return !apiFilesNames.some((existingFile) => existingFile === newFile.fileName)
    })


    setFiles((prevFiles) => [...prevFiles, ...validApiFiles]);

    setLocalFiles((prevAttachments) => [...prevAttachments, ...validApiFiles]);
  };

  React.useEffect(() => {
    const optionSelect = document.getElementById("formSelect-st-pending");

    if (keepChassis === true) {
      const check = document.getElementById("keepChassisYes");
      check.checked = true;
    }
    else if (keepChassis === false) {
      const check = document.getElementById("keepChassisNo");
      check.checked = true;
    }
  }, [keepChassis]);


  const handleRemoveAttachment = (index) => {

    setLocalFilesNames((prevAttachments) => {
      const updatedAttachments = [...prevAttachments];
      updatedAttachments.splice(index, 1);
      return updatedAttachments;
    });

    setLocalFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    if (event.target.value === "LineAproved") {

      setStreetTurnInput(true);
      setKeepChassis(false);
    } else {
      setStreetTurnInput(false);
    }
  };

  const removeExtraSpace = (s) => s.trim().split(/ +/).join(" ");


  const handleSaveChange = async () => {
    setSavingStatus(true)
    const inputsModal = readElementsById();
    const Id = inputsModal.Id.value;
    const scac = inputsModal.scacText.value;
    const statusaprove = inputsModal.formSelect.value;

    let streetTurnDate = null
    if (statusaprove === "LineAproved") {
      streetTurnDate = dateStSelected;
    }
    const streetTurnDateUTC = convertToUTC(streetTurnDate);

    const changeOfScac = inputsModal.changeOfScac
      ? inputsModal.changeOfScac.value
      : null;
    const newModalData = {
      Id,
      scac,
      statusaprove,
      streetTurnDateUTC,
      changeOfScac,
      keepChassis,
    };
    setNewModalData(newModalData);

    const requestData = {
      Id: newModalData.Id,
      NewStatus: removeExtraSpace(newModalData.statusaprove),
      UserName: user.email[0],
      ScacCodeContainer: newModalData.scac,
      ScacCodeStreetTurn: newModalData.changeOfScac,
      NeedChassis: newModalData.keepChassis,
      Files: files,
      StartDate: newModalData.streetTurnDateUTC,
    };

    UpdateStatusStreetTurn(requestData)
      .then((response) => {
        handleSetToastMessage(response.data.Message);
        handleSetToastType(response.data.IsSuccess ? "success" : "warning");
        handleSetShowToast(true);
        handleCloseModal();
        const eventFetchData = new CustomEvent("fetchPendingSt", { detail: null });
        window.dispatchEvent(eventFetchData);
        setSavingStatus(false);
      })
      .catch((error) => {
        handleSetToastMessage(error.response.data.Message);
        handleSetToastType("error");
        handleSetShowToast(true);
        handleCloseModal();
        const eventFetchData = new CustomEvent("fetchPendingSt", { detail: null });
        window.dispatchEvent(eventFetchData);
        setSavingStatus(false)
      });

  };


  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        handleSetShowToast(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const handlePrevImage = () => { };

  const handleSetShowToast = (showToast) => {
    setShowToast(showToast);
  };

  const handleSetToastMessage = (toastMessage) => {
    setToastMessage(toastMessage);
  };

  const handleSetToastType = (toastType) => {
    setToastType(toastType);
  };

  const handleScacChange = (event) => {
    // verify if length is 4 and uppercase all letters, also do not allow spaces and special characters
    const scac = event.target.value;
    const changeOfScac = document.getElementById("changeOfScac");

    // remove spaces and special characters
    const scacWithoutSpaces = scac.replace(/\s+/g, "");
    const scacWithoutSpecialCharacters = scacWithoutSpaces
      .replace(/[^a-zA-Z ]/g, "")
      .toUpperCase();

    if (scacWithoutSpecialCharacters.length > 4) {
      changeOfScac.value = scacWithoutSpecialCharacters.slice(0, 4);

      return;
    } else {
      changeOfScac.value = scacWithoutSpecialCharacters;
    }
    if (scacWithoutSpecialCharacters.length === 4) {
      setNewScacSelected(changeOfScac.value);
    }
    else {
      setNewScacSelected(null);
    }

  };


  //PRUEBAS
  const openFile = (fileName) => {


    for (const element of apiFilesNames) {


      if (fileName === element) {
        let file = IdOfFiles + '/' + fileName;
        const url = 'https://apim-yms-street-turn-test.azure-api.net/streetTurn/DownloadFile?filePath=';

        const newTab = window.open();
        if (newTab) {
          newTab.location.href = url + file;

          setTimeout(() => {
            newTab.close();
          }, 2000);
        }


        return
      } else {

        const matchingFile = localFiles.find((attachment) => attachment.fileName === fileName);

        if (matchingFile) {
          const base64Data = matchingFile.file;
          const contentType = matchingFile.type;

          const byteCharacters = atob(base64Data);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          const blob = new Blob(byteArrays, { type: contentType });
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl, "_blank");

          const link = document.createElement("a");
          link.href = fileUrl;
          link.target = "_blank";
          link.download = fileName;

          document.body.appendChild(link);

          link.click();
          document.body.removeChild(link);


          return;
        }
      }
    }
  };


  React.useEffect(() => {
    if (selectedStatus === "LineAproved") {
      if (newScacSelected && dateStSelected) {
        setSaveAvailable(true)

      }
      else {

        setSaveAvailable(false)
      }


    } else if (selectedStatus === "PendingFlip") {
      setSaveAvailable(false)
    }
    else {
      setSaveAvailable(true)
    }
  }, [dateStSelected, keepChassis, newScacSelected, selectedStatus])

  const handleStDateChange = (event) => {
    if (event.target.value === "") {
      setDateStSelected(null)
    }
    else {
      setDateStSelected(event.target.value)
    }
  }





  return {
    selectedStatus,
    showToast,
    toastMessage,
    toastType,
    fileName,
    fileInputRef,
    handleCloseModal,
    handleFileChange,
    handleStatusChange,
    handleSaveChange,
    streetTurnInput,
    handlePrevImage,
    handleScacChange,
    attachments,
    handleRemoveAttachment,
    openFile,
    setKeepChassis,
    keepChassis,
    listShowedOfFiles,
    lineApprovedComing,
    handleStDateChange,
    dateStSelected,
    newScacSelected,
    saveAvailable,
    savingStatus,
  };
};
export default useModalEditController;
