import axios from "axios";
import json from "./mockup.json";
export const fetchJson = async () => {
  try {
    return json;
  } catch (error) {
    console.log(error);
  }
};

// create StreetTurn function
export const GetAllStreetTurn = async (data) => {
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_GET_APIM_OCP_KEY,
    "Content-Type": "application/json",
  };
  const config = {
    headers: headers,
    method: "get",
    url: process.env.REACT_APP_GET_ALL_STREET_TURN,
  };
  return axios(config);
};

// Update status StreetTurn function
export const UpdateStatusStreetTurn = async (data) => {
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_GET_APIM_OCP_KEY,
    "Content-Type": "application/json",
  };
  const config = {
    headers: headers,
    method: "post",
    url: process.env.REACT_APP_UPDATE_STATUS_STREET_TURN,
    data: data,
  };
  return axios(config);
};

export const GetFileDownload = async (file) => {

  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_GET_APIM_OCP_KEY,
    "Content-Type": "application/json",
  }
  fetch(process.env.REACT_APP_FETCH_FILE_DOWNLOAD + file,)


}

export const DeleteDropOff = async (id) => {
  const headers = {
    "Ocp-Apim-Subscription-Key": process.env.REACT_APP_GET_APIM_OCP_KEY,
    "Content-Type": "application/json",
  };
  const config = {
    headers: headers,
    method: "post",
    url: process.env.REACT_APP_DELETE_DROP_OFF,
    data: id,
  };
  return axios(config);
}