import * as React from 'react'
import { StoreContext } from '../../store/storeContext'
const useButtonsController = () => {
    const { filterIntoTable, setFilterIntoTable,containerFiltered ,setContainerFiltered } = React.useContext(StoreContext)
    return {
        filterIntoTable,
        setFilterIntoTable,
        containerFiltered,
        setContainerFiltered
        
    }
}
export default useButtonsController