import React, { useEffect, useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./statusTable.css";
import useStatusTableController from "./statusTableController";
import { FiEdit } from "react-icons/fi";
import { StoreContext } from "../../store/storeContext";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";
import { IoEye } from "react-icons/io5";
import ToasterNotification from "../toasterNotification/ToasterNotification";
import { BiTrash } from "react-icons/bi";
const StatusTable = () => {
  let { handleFillShownModal } = useContext(StoreContext);

  const { data, renderEmptyCell, renderStatus, showCompleted, ToastObject, handleDeleteDropOff, setShowCompleted, handleSortByRequestStatus, handleSortByRequestDate, handleShowDropOffCompleted, show, filterIntoTable, title, localTableData } = useStatusTableController();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10); // Estado para el número de elementos por página
  const totalPages = Math.ceil(localTableData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = localTableData.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [showCompleted]);
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value)); // Actualizar el estado del número de elementos por página
    setCurrentPage(1); // Restablecer la página actual a la primera cuando cambia el número de elementos por página
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterIntoTable]);

  const handleEditDropOff = (show, item) => {
    const showDropOff = new CustomEvent('editDropOff', { detail: { show, item } });
    window.dispatchEvent(showDropOff);
  }
  return (
    <>
      <div id="title-st-pending">
        <div >
          {filterIntoTable === 'dropoff' ? <div class="form-check form-switch" style={{ marginLeft: '35px', display: 'flex', height: '100%', verticalAlign: 'middle', alignItems: 'center', }} onChange={(e) => handleShowDropOffCompleted()}>
            <input class="form-check-input" checked={showCompleted} type="checkbox" role="switch" id="flexSwitchCheckDefault" style={{ backgroundColor: showCompleted ? '#fc4817' : 'white', borderColor: 'white' }} />
            <p style={{ fontSize: '10px', height: '100%', alignItems: 'end', display: 'flex', paddingLeft: '8px' }}>SHOW COMPLETED</p>
          </div>
            : null}
        </div>
        <div id="titleText-st-pending">
          {title}
        </div>
      </div>
      {show ? (
        <div class="d-flex justify-content-center" style={{ minHeight: '70vh' }}>
          <div class="spinner-border" role="status" style={{ alignSelf: 'center', scale: '5' }}>
          </div>
        </div>
      ) : (<>
        <div className={"table-responsive table-st-pending"}>
          <table className={`table table-striped table-borderless table-hover`}>
            <thead id="table-headers-st-pending" style={{ verticalAlign: "middle" }}>
              <tr>
                {filterIntoTable !== 'dropoff' ?
                  <React.Fragment>
                    <th
                      style={{
                        paddingLeft: "50px",
                        paddingBottom: "20px",
                        paddingTop: "20px",
                      }}
                    >
                      SCAC
                    </th>
                    <th>SSL</th>
                    <th>S/T</th>
                    <th>Cont/Trl Num</th>
                    <th>Chassis Num</th>
                    <th>LFD</th>
                    <th>Status</th>
                    <th>Arrival</th>
                    <th>Location</th>
                    <th>Spot</th>

                    <th>Inspection Status</th>
                    <th id="sortColumn-st-pending" onClick={handleSortByRequestDate}>Request Date</th>
                    <th id="sortColumn-st-pending" onClick={handleSortByRequestStatus}>Request Status</th>

                    <th></th>
                    <th style={{ paddingRight: "50px" }}>Options</th>
                  </React.Fragment>
                  : <React.Fragment>  <th
                    style={{
                      paddingLeft: "50px",
                      paddingBottom: "20px",
                      paddingTop: "20px",
                    }}
                  >
                    TMP
                  </th>
                    <th>Delivery Date</th>
                    <th>SSL</th>
                    <th>S/T</th>
                    <th>Cont/Trl Num</th>
                    <th>Chassis Num</th>
                    <th>SNE / OWNER OP</th>
                    <th>Status</th>
                    <th>Arrival</th>
                    <th>Location</th>
                    <th>Cus/Del Location</th>
                    <th>Food Grade</th>
                    <th>Inspect. Status</th>
                    <th id="sortColumn-st-pending" onClick={handleSortByRequestDate}>Request Date</th>
                    <th id="sortColumn-st-pending" onClick={handleSortByRequestStatus}>Request Status</th>
                    <th style={{ paddingRight: "50px" }}>Options</th>
                  </React.Fragment>
                }
              </tr>
            </thead>
            <tbody id="tbody-style-st-pending">
              {currentData.map((item, index) => {
                const styleStatus = renderStatus(item, index);

                return (
                  <tr key={index} style={{ marginBottom: "20px" }}>
                    {filterIntoTable !== 'dropoff' ?
                      <React.Fragment>
                        <td style={{ paddingLeft: "50px" }}>{item.scac}</td>
                        <td>{item.ssl}</td>
                        <td>{item.s_t}</td>
                        <td>{item.cont_trl_num}</td>
                        <td style={{ color: item.chassis_n === "GROUNDED" ? '#fc4817' : '' }}>{item.chassis_n}</td>
                        <td>{item.lfd}</td>
                        <td>{item.status}</td>
                        <td>{item.arrival}</td>
                        <td>{item.location}</td>
                        <td>{item.spot}</td>
                        <td style={{ color: item.statusInspection === "Completed" || item.statusInspection === "N/A" ? 'black' : 'red' }}>{item.statusInspection}</td>
                        <td>{item.requestDate}</td>
                        <td style={styleStatus.style}>{styleStatus.text}</td>
                        <td>{renderEmptyCell(item, index)}</td>
                        {filterIntoTable === 'pending' ? <td style={{ paddingRight: "1%" }}>
                          <FiEdit
                            id="optionIcon"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            onClick={() => handleFillShownModal(true, item)}
                          />
                        </td> : null}
                        {filterIntoTable === 'completed' ? <td style={{ paddingRight: "1%" }}>
                          <IoEye
                            id="optionIcon"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            onClick={() => handleFillShownModal(true, item)}
                          />
                        </td>
                          : null}
                      </React.Fragment> : <>
                        <td style={{ paddingLeft: "50px" }}>{item.tmp}</td>
                        <td>{item.deliveryDate}</td>
                        <td>{item.ssl}</td>
                        <td>{item.s_t}</td>
                        <td>{item.cont_trl_num}</td>
                        <td style={{ color: item.chassis_n === "GROUNDED" ? '#fc4817' : '' }}>{item.chassis_n}</td>
                        <td>{item.carrier}</td>
                        <td>{item.status}</td>
                        <td>{item.arrival}</td>
                        <td>{item.location}</td>
                        <td>{item.deliveryLocation}</td>
                        <td>{item.foodGrade}</td>
                        <td>{item.statusInspection}</td>
                        <td>{item.requestDate}</td>
                        <td style={styleStatus.style}>{styleStatus.text}</td>
                        {item.statusaprove === 'DropOffInTransit' ? <td style={{ paddingRight: "1%" }}>

                          <FiEdit
                            id="optionIcon-st-pending"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasRight"
                            aria-controls="offcanvasRight"
                            onClick={(e) => handleEditDropOff(true, item)}
                          />
                          <BiTrash
                            id="optionIcon-st-pending"
                            type="button"
                            style={{ color: 'red' }}
                            onClick={(e) => handleDeleteDropOff(item)}
                          />
                        </td>
                          : <td></td>}
                      </>}
                  </tr>
                );
              })}
            </tbody>
          </table>

        </div>
        <div className=" paginationcss-st-pending">
          <ul className="pagination" style={{ alignItems: 'baseline' }}>
            <p style={{ paddingRight: '10px' }}>Rows per page:</p>
            <div className="dropdown" >
              <select
                id="itemsPerPage"
                className="form-select"
                aria-label="Items per page"
                onChange={handleItemsPerPageChange}
                value={itemsPerPage}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
            </div>
            <p style={{ paddingLeft: '10px', paddingRight: '10px' }}>{startIndex + 1} to {Math.min(endIndex, data.length)} of {data.length}</p>
            <FiChevronLeft
              id="arrowToLeft-st-pending "
              onClick={() => handlePageChange(currentPage - 1)}

              disabled={currentPage === 1}
            />
            <FiChevronRight id="arrowToRight-st-pending" className={` ${currentPage === totalPages ? 'disabled' : ''}`} onClick={() => handlePageChange(currentPage + 1)} />
          </ul>
        </div>
      </>
      )}
      <ToasterNotification
        showToast={ToastObject.show}
        toastMessage={ToastObject.message}
        toastType={ToastObject.type}
      />
    </>
  );
};

export default StatusTable;
