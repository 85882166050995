// useStatusTableController.js
import React, { useState, useEffect, useContext } from "react";
import { fetchJson, GetAllStreetTurn, DeleteDropOff } from "../../services/services"; // Asegúrate de ajustar la ruta correcta
import { StoreContext } from "../../store/storeContext";
import sslModel from "../../models/registerStatusModel"; // Asegúrate de ajustar la ruta correcta

const useStatusTableController = () => {
  const { data, handleFillData, handleFillResponseData, filterIntoTable, user, originalData, setOriginalData, setFilterIntoTable, containerFiltered, setContainerFiltered, fetchingData } =
    useContext(StoreContext);
  const [sorted, setSorted] = useState(false);
  const [sortedRequestDate, setSortedRequestDate] = useState(false);
  const [show, setShow] = useState(true);
  const [title, setTitle] = useState("ST IN PROGRESS");
  const [showCompleted, setShowCompleted] = useState(false);
  const [localTableData, setLocalTableData] = useState([])
  const [ToastObject, setToastObject] = useState({ show: false, message: '', type: '' })
  const fetchData = async () => {
    setShow(true);
    try {

      GetAllStreetTurn()
        .then((res) => {
          handleFillResponseData(res.data.streetTurns);
          const sslModels = res.data.streetTurns.map(
            (item) => new sslModel(item)
          );
          setOriginalData(sslModels);
          setFilterIntoTable(null)
          setFilterIntoTable('pending')

          setShow(false);

        })

        .catch((err) => {
          console.log(err);
          setShow(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const renderEmptyCell = (item, index) => {
    if (item.statusaprove === "Line Aproved") {
      return (
        <button type="button" className="btn btn-primary table-button-st-pending">
          Adjuntar
        </button>
      );
    }
    return null;
  };

  useEffect(() => {
    fetchData();
    window.addEventListener("fetchPendingSt", fetchData);
  }, []);
  useEffect(() => {
    handleSortByRequestDate();
  }, [data]);

  useEffect(() => {
    if (fetchingData === null) {
      return;
    } else {
      fetchData();
    }
  }, [fetchingData]);
  const renderStatus = (item, index) => {
    const status = item.statusaprove;

    switch (status) {
      case "LineAproved":
        return {
          style: { color: "#44C767", fontWeight: 700 },
          text: "Line Approved",
        };
      case "LinePending":
        return {
          style: { color: "#FFC300", fontWeight: 700 },
          text: "Line Pending",
        };
      case "CarrierAproved":
        return {
          style: { color: "#1578FA", fontWeight: 700 },
          text: "Carrier Approved",
        };
      case "CarrierPending":
        return {
          style: { color: "#FD4917", fontWeight: 700 },
          text: "Carrier Pending",
        };
      case "Finished":
        return {
          style: { color: "#6ccb5f", fontWeight: 700 },
          text: "ST Completed",
        };
      case "PendingFlip":
        return {
          style: { color: "#51bbeb", fontWeight: 700 },
          text: "Pending Flip",
        };
      case "Cancelled":
        return {
          style: { color: "#731114", fontWeight: 700 },
          text: "Cancelled",
        };
      case "DropOffCompleted":
        return {
          style: { color: "#6ccb5f", fontWeight: 700 },
          text: "Drop Off Completed",
        };
      case "DropOffInTransit":
        return {
          style: { color: "#fc4817", fontWeight: 700 },
          text: "Drop Off In Transit",
        };
      default:
        return {};
    }
  };


  const handleSortByRequestStatus = () => {
    if (!sorted) {
      localTableData.sort((a, b) => {
        if (a.statusaprove > b.statusaprove) {
          return 1;
        }
        if (a.statusaprove < b.statusaprove) {
          return -1;
        }
        return 0;
      });
      setSorted(true);
    }
    else {
      localTableData.sort((a, b) => {
        if (a.statusaprove < b.statusaprove) {
          return 1;
        }
        if (a.statusaprove > b.statusaprove) {
          return -1;
        }
        return 0;
      });
      setSorted(false);
    }
  }



  const handleSortByRequestDate = () => {
    if (!sortedRequestDate) {
      localTableData.sort((a, b) => {
        if (a.requestDate > b.requestDate) {
          return 1;
        }
        if (a.requestDate < b.requestDate) {

          return -1;
        }
        return 0;
      });

      setSortedRequestDate(true);
    }
    else {
      localTableData.sort((a, b) => {
        if (a.requestDate < b.requestDate) {
          return 1;
        }
        if (a.requestDate > b.requestDate) {

          return -1;
        }
        return 0;
      });

      setSortedRequestDate(false);
    }
  }

  React.useEffect(() => {
    handleFilterIntoTable()
    setContainerFiltered('')
  }, [filterIntoTable])

  const handleFilterIntoTable = (showCompletedFilter) => {
    if (filterIntoTable === 'pending') {
      const dataFilter = originalData.filter((item) => item.statusaprove !== 'Finished' && item.statusaprove !== 'DropOffCompleted' && item.statusaprove !== 'DropOffInTransit');
      handleFillData(dataFilter);
      setLocalTableData(dataFilter)
      setTitle("ST IN PROGRESS")
    }
    else if (filterIntoTable === 'completed') {
      const dataFilter = originalData.filter((item) => item.statusaprove === 'Finished');
      handleFillData(dataFilter);
      setLocalTableData(dataFilter)
      setTitle("ST COMPLETED")
    }
    else if (filterIntoTable === 'dropoff') {
      let dataFilter = originalData.filter((item) => item.statusaprove === 'DropOffCompleted' || item.statusaprove === 'DropOffInTransit');
      if (!showCompletedFilter) {
        dataFilter = dataFilter.filter((item) => item.statusaprove === 'DropOffInTransit');
      }
      handleFillData(dataFilter);
      setLocalTableData(dataFilter)
      setTitle("EMPTIES DROP OFF")
    }

  }

  const filterByContainer = (item) => {
    if (containerFiltered) {
      const dataFilter = data.filter((element) => {
        if (item !== '' && item !== null && item !== undefined) {
          const itemContainerStr = item.toString().toUpperCase()
          const elementContainerStr = element.cont_trl_num.toString().toUpperCase()
          return elementContainerStr.includes(itemContainerStr)
        }
        else {
          return element
        }

      });
      setLocalTableData(dataFilter);
    } else {
      setLocalTableData(data)
    }
    return true
  }

  useEffect(() => {
    filterByContainer(containerFiltered)
  }, [containerFiltered])


  const handleShowDropOffCompleted = () => {
    setShowCompleted(!showCompleted)
    handleFilterIntoTable(!showCompleted)
  }

  const handleDeleteDropOff = (id) => {

    const object = {
      idEmptyDropOff: id.Id,
      user: user.email[0]
    }
    DeleteDropOff(object)
      .then((res) => {
        setToastObject({ show: true, message: res.data.message, type: 'success' })
        console.log(res)
        setTimeout(() => {
          setToastObject({ show: false, message: '', type: '' })
        }, 5000)
        fetchData()
      })
      .catch((err) => {
        setToastObject({ show: true, message: 'Error Deleting Drop Off' + err, type: 'error' })
        setTimeout(() => {
          setToastObject({ show: false, message: '', type: '' })
        }, 5000)
        console.log(err);
      });
  }
  return {
    data,
    handleFillData,
    renderEmptyCell,
    renderStatus,
    containerFiltered,
    handleSortByRequestStatus,
    handleSortByRequestDate,
    show,
    filterIntoTable,
    title,
    localTableData,
    handleShowDropOffCompleted,
    showCompleted,
    setShowCompleted,
    handleDeleteDropOff,
    ToastObject,
  };
};

export default useStatusTableController;
