import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import StatusTable from "./components/statusTable/statusTable";
import ModalEdit from "./components/modalEdit/modalEdit";
import Buttons from "./components/Buttons/Buttons";
import { StoreProvider } from "./store/storeContext";

export default function Root(props) {
  return (

    <React.StrictMode>
      <StoreProvider>
        <div className="App-st-pending">
          <div className="App-table-st-pending">
            <Buttons />
            <section id="tableSection-st-pending">
              <StatusTable />
            </section>
            <ModalEdit />
          </div>
        </div>
      </StoreProvider>
    </React.StrictMode>

  );
}
