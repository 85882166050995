export const convertUtcToMMDDYYHHMM = (utcDate) => {
  const utcTime = new Date(utcDate);

  // Obtener los componentes de fecha y hora en la zona horaria local
  const day = utcTime.getDate();
  const month = utcTime.getMonth() + 1; // Los meses en JavaScript son basados en cero
  const year = utcTime.getFullYear();
  const hours = utcTime.getHours().toString().padStart(2, '0');
  const minutes = utcTime.getMinutes();

  // Formatear la hora local en MM/DD/YYYY HH:MM
  const localTimeFormatted = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return localTimeFormatted;
};

export const convertUtcToMMDD = (utcDate) => {
  const date = new Date(utcDate);

  // Obtener componentes de la fecha en formato UTC
  const month = date.getUTCMonth() + 1; // months from 0 to 11
  const day = date.getUTCDate();

  // Formatear el mes y el día con dos dígitos cada uno
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;

  return `${formattedMonth}/${formattedDay}`;
};

export const convertUtcToMMDDYY = (utcDate) => {
  const utcTime = new Date(utcDate);

  // Obtener los componentes de fecha y hora en la zona horaria local
  const day = utcTime.getDate();
  const month = utcTime.getMonth() + 1; // Los meses en JavaScript son basados en cero
  const year = utcTime.getFullYear();


  // Formatear la hora local en MM/DD/YYYY HH:MM
  const localTimeFormatted = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;

  return localTimeFormatted;
};