import React, { useContext, useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { StoreContext } from "../../store/storeContext";
import "./modalEdit.css";
import { BsPaperclip } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";
import { BiCaretLeft } from "react-icons/bi";
import { GoContainer } from "react-icons/go";
import { IoMdTrash } from "react-icons/io";
import useModalEditController from "./modalEditController";
import ToasterNotification from "../toasterNotification/ToasterNotification.jsx";

const OptionsTableModal = () => {
  let { shownModal } = useContext(StoreContext);

  const {
    handleCloseModal,
    handleFileChange,
    handleStatusChange,
    handleScacChange,
    setKeepChassis,
    keepChassis,
    selectedStatus,
    fileName,
    fileInputRef,
    handleSaveChange,
    streetTurnInput,
    showToast,
    toastMessage,
    toastType,
    attachments,
    handleRemoveAttachment,
    openFile,
    listShowedOfFiles,
    lineApprovedComing,
    handleStDateChange,
    dateStSelected,
    newScacSelected,
    saveAvailable,
    savingStatus,
  } = useModalEditController();

  return (
    <>
      <div
        className={`offcanvas offcanvas-end  ${shownModal ? "show" : ""}`}
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasWithBackdrop"
        aria-labelledby="offcanvasWithBothOptionsLabel"
        data-bs-keyboard="esc"
        style={{ top: '72px' }}
      >

        <div className="offcanvas-header offcanvas-header-st-pending">

          <div className="col-11" id="headerDetails-st-pending">
            <BiCaretLeft
              type="button"
              id="closeButtonModal-st-pending"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleCloseModal}
            />
            <GoContainer id="goContainer-st-pending" />
            <h5 className="offcanvas-title" id="staticBackdropLabel">
              Container Details
            </h5>
          </div>


        </div>

        <div className="offcanvas-body">
          <div className="container-fluid">
            <div className="row row-modal-edit-st-pending">
              <div className="col-12" style={{ paddingBottom: "3%" }}></div>
              <input
                hidden
                type="text"
                className="form-control upperText-st-pending"
                id="Id"
              />
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control upperText-st-pending"
                    id="scacText"
                    placeholder="Scac"
                    maxLength={4}
                    disabled={true}
                  />
                  <label for="floatingInput">SCAC Code</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control upperText-st-pending"
                    id="sslText"
                    placeholder="SSL"
                    disabled={true}
                  />
                  <label for="floatingInput">SSL</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control "
                    id="stText"
                    placeholder="S/T"
                    disabled={true}
                  />
                  <label for="floatingInput">S/T</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control upperText-st-pending"
                    id="contNumText"
                    placeholder="Con/Trl Num"
                    disabled={true}
                  />
                  <label for="floatingInput">Cont/Trl Num</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control upperText-st-pending"
                    id="chassisText"
                    placeholder="Chassis N"
                    disabled={true}
                  />
                  <label for="floatingInput">Chassis N</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    className="form-control"
                    id="lfdDate"
                    placeholder="LFD"
                    disabled={true}
                  />
                  <label for="floatingInput">LFD</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="statusText"
                    placeholder="Status"
                    disabled={true}
                  />
                  <label for="floatingInput">Status</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="date"
                    className="form-control"
                    id="arrivalDate"
                    placeholder="Arrival"
                    disabled={true}
                  />
                  <label for="floatingInput">Arrival</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div class="form-floating mb-3">
                  <input
                    type="text"
                    class="form-control"
                    id="locationText"
                    placeholder="Location"
                    disabled={true}
                  />
                  <label for="floatingInput">Location</label>
                </div>
              </div>
              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="spotText"
                    placeholder="Spot"
                    disabled={true}
                  />
                  <label for="floatingInput">Spot</label>
                </div>
              </div>

              <hr style={{ paddingTop: "5%" }}></hr>

              <div className="col-6" id="textfieldModal-st-pending">
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    id="formSelect-st-pending"
                    aria-label="Default select example"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  >
                    <option selected hidden>
                      Select Status
                    </option>
                    <option value="CarrierPending" style={{ color: "#FD4917" }}>
                      Carrier Pending
                    </option>
                    <option value="CarrierAproved" style={{ color: "#1578FA" }}>
                      Carrier Approved
                    </option>
                    <option value="LinePending" style={{ color: "#FFC300" }}>
                      Line Pending
                    </option>
                    <option value="LineAproved" style={{ color: "#44C767" }}>
                      Line Approved
                    </option>
                  </select>
                  <label for="floatingInput">Status of SSL</label>
                </div>
              </div>

              {streetTurnInput && (
                <>
                  <div className="col-6" id="textfieldModal-st-pending">
                    <div className="form-floating mb-3">
                      <input
                        type="date"
                        className={`form-control  ${dateStSelected === null ? 'borderToSt-st-pending' : ''}`}
                        id="streetTurnDate-st-pending"
                        onChange={handleStDateChange}
                      />
                      <label for="floatingInput" id="streetTurnLabel">
                        Street Turn Date
                      </label>
                    </div>
                  </div>

                  <div className="col-6" id="textfieldModal-st-pending">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        className={`form-control  ${newScacSelected === null ? 'borderToSt-st-pending' : ''}`}
                        id="changeOfScac"
                        placeholder="Change of SCAC"
                        onChange={handleScacChange}
                      />
                      <label for="floatingInput">Change of SCAC</label>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="col-12">
                      <p>
                        ¿Keep Chassis?
                      </p>
                    </div>
                    <div className="col-12" style={{ display: 'flex' }}>
                      <div className="col-6">
                        <label>
                          <input
                            type="radio"
                            name="exampleRadios"
                            id="keepChassisYes"
                            value={true}
                            className={keepChassis === true ? "checked" : ""}
                            onClick={() => setKeepChassis(true)}
                          />
                          Yes
                        </label>
                      </div>
                      <div className="col-6">
                        <label>
                          <input
                            type="radio"
                            name="exampleRadios"
                            id="keepChassisNo"
                            value={false}
                            className={keepChassis === false ? "checked" : ""}
                            onClick={() => setKeepChassis(false)}
                          />
                          No
                        </label>
                        <br />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="row row-modal-edit-st-pending">
              {streetTurnInput && !lineApprovedComing && (
                <div className="col-6">
                  <label className="btn btn-primary attach-st-pending">
                    <span id="customFileLabel">
                      <BsPaperclip style={{ marginRight: "10%" }}></BsPaperclip>
                      {fileName}
                    </span>
                    <input
                      type="file"
                      id="formFile"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      multiple={true}
                    />
                  </label>
                </div>
              )}

              {streetTurnInput && lineApprovedComing && (
                <div>
                </div>
              )}

              {!streetTurnInput && <div className="col-6" />}
              {lineApprovedComing ? (<div />) : (
                < div className="col-6">
                  <div
                    className={`btn btn-success ${saveAvailable === false || savingStatus === true ? 'disabled' : ''}`}
                    id="btnModal-st-pending"
                    onClick={handleSaveChange}
                 

                  >
                    {savingStatus ? <> <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>   Loading...</> : <>
                      <AiOutlineSave style={{ marginRight: "10%" }} />
                      Save</>
                    }
                  </div>

                </div>
              )}
            </div>
            <div className="col-12 mt-3">
              <ul style={{ textAlign: "left", paddingInlineStart: "0", display: 'grid' }}>
                {listShowedOfFiles.map((attachment, index) => (
                  <li key={index} style={{ display: '-webkit-inline-box', fontSize: "12px", paddingBottom: '1%' }}>
                    {lineApprovedComing ? (<div />) : (<IoMdTrash id="trashIcon-st-pending" onClick={() => handleRemoveAttachment(index)}>
                      Remove
                    </IoMdTrash>)}


                    <p id="fileNameAttached-st-pending" onClick={e => openFile(attachment)}>{attachment}</p>

                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div >
      <ToasterNotification
        showToast={showToast}
        toastMessage={toastMessage}
        toastType={toastType}
      />
    </>
  );
};

export default OptionsTableModal;
