import 'bootstrap/dist/css/bootstrap.min.css';
import useButtonsController from './ButtonsController';
import './Buttons.css'
const Buttons = () => {

    const { filterIntoTable, setFilterIntoTable, containerFiltered, setContainerFiltered } = useButtonsController();

    return (
        <>
            <div className="row" style={{ paddingBottom: '1%', paddingRight: '1%' }}>
                <div className="col-xxl-4 col-xl-4 col-lg-0 col-md-0 " />
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6" style={{ paddingRight: '10px' }}>
                    <div class="form-floating">
                        <input class="form-control" placeholder="SearchByContainer" id="floatingTextarea"
                            onChange={(e) => setContainerFiltered(e.target.value.toUpperCase())} value={containerFiltered}
                        ></input>
                        <label for="floatingTextarea">Search by Container</label>
                    </div>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6" style={{ paddingRight: '10px' }}>
                    <button type="button" className={` btn-table-st-pending ${filterIntoTable === 'pending' ? 'selected-button-st-pending' : 'filter-button-st-pending'}`} onClick={(e) => setFilterIntoTable('pending')}>
                        ST IN PROGRESS
                    </button>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6" style={{ paddingRight: '10px' }} >
                    <button type="button" className={` btn-table-st-pending ${filterIntoTable === 'completed' ? 'selected-button-st-pending' : 'filter-button-st-pending'}`} onClick={(e) => setFilterIntoTable('completed')}>
                        ST COMPLETED
                    </button>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-6" >
                    <button type="button" className={` btn-table-st-pending ${filterIntoTable === 'dropoff' ? 'selected-button-st-pending' : 'filter-button-st-pending'}`} onClick={(e) => setFilterIntoTable('dropoff')}>
                        EMPTIES DROP OFF
                    </button>
                </div>
            </div >
        </>
    )
}

export default Buttons;