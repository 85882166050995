import React, { useState, createContext, useEffect } from "react";
export const StoreContext = createContext(null);
import Cookies from 'universal-cookie';
export const StoreProvider = ({ children }) => {

  //GLOBAL CONSTANTS
  const [data, setData] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [shownModal, setShownModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [registerToSave, setRegisterToSave] = useState({});
  const [filterIntoTable, setFilterIntoTable] = useState(null)
  const [originalData, setOriginalData] = useState([])
  const [containerFiltered, setContainerFiltered] = useState(null)
  const [fetchingData, setFetchingData] = useState(null)
  const cookies = new Cookies(null, { path: '/' });
  const [user, setUser] = useState({
    name: "",
    email: [

    ],
  }
  )
  //USE EFFECT TO SET USER
  useEffect(() => {
    if (cookies.get("sessionCosmos")) {
      setUser(cookies.get("sessionCosmos"))
    } else {
      cookies.addChangeListener((session) => {
        if (session.name === "sessionCosmos") {
          if (session.value !== undefined) {
            setUser(session.value)
          } else {
            window.location.href = '/home'
          }
        }
      })
    }
  }, []);

  //HANDLERS
  const handleFillData = (resource) => {
    setData(resource);
  };

  const handleFillResponseData = (resource) => {
    setResponseData(resource);
  };


  const handleFillShownModal = (showModal, item) => {
    setShownModal(false)
    setModalData([])
    if (showModal) {
      responseData.filter((element) => {
        if (element.Container === item.cont_trl_num) {
          setModalData(element);
        }
      });
      setShownModal(showModal);
    } else {
      setShownModal(showModal);
    }
  };
  const handleFetchData = () => {
    if (fetchingData === null) {
      setFetchingData(true)
    } else {
      setFetchingData(!fetchingData)
    }
  }


  return (
    <StoreContext.Provider
      value={{
        data,
        handleFillData,
        handleFillShownModal,
        shownModal,
        modalData,
        handleFillResponseData,
        responseData,
        handleFetchData,
        fetchingData,
        filterIntoTable,
        setFilterIntoTable,
        originalData,
        setOriginalData,
        containerFiltered,
        setContainerFiltered,
        user,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
